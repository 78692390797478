<template>
  <div class="contenedor">
    <div class="div-flotante" v-if="sesion != 'meditacion'">
      <b-button
        variant="primary"
        style="font-size: 20px"
        @click="showModal()"
        >{{ $t("messages.agendarSesion") }}</b-button
      >
    </div>
    <div
      class="container-div-flotante-in-title"
      style="width: 100%; height: 60px"
    >
      <span class="div-flotante-in-title"
        ><b-button
          variant="primary"
          style="font-size: 20px"
          @click="showModal()"
          >{{ $t("messages.agendarSesion") }}</b-button
        ></span
      >
    </div>

    <!-- tarot -->

    <div v-if="sesion === 'tarot'">
      <h1 class="title-vista">{{ $t("messages.tarotTitle") }}</h1>
      <p class="parrafo-justificado">
        {{
          $i18n.locale === "en"
            ? "Tarot is a powerful and beautiful spiritual tool for personal self-discovery. It helps you gain perspective during difficult times, when making a complicated decision, or when you need clarity and mental illumination in life situations."
            : "El tarot es una poderosa y bella herramienta espiritual de autoconocimiento personal. Te ayuda a ganar perspectiva en momentos difíciles, a la hora de tomar una decisión complicada o cuando necesitas luz y claridad mental en situaciones de tu vida."
        }}
      </p>

      <p class="parrafo-justificado">
        {{
          $i18n.locale === "en"
            ? "We are all unique beings, and we shine in harmony when we are aligned with your own personal truth. Tarot guides you on this journey by revealing the energies of your present moment, reflecting universal or archetypal patterns of the human experience that are useful to you."
            : "Todos somos seres únicos y brillamos en armonía cuando estamos alineados con nuestra verdad personal. El tarot te guía en este camino, aportando claridad a las energías de tu momento presente, reflejando patrones universales o arquetípicos de la experiencia humana que son útiles para ti."
        }}
      </p>

      <p class="parrafo-justificado">
        <cite>
          {{
            $i18n.locale === "en"
              ? "“Until you make the unconscious conscious, it will direct your life, and you will call it fate.” - Carl Jung."
              : "“Hasta que hagas tu inconsciente consciente, dirigirá tu vida y lo llamarás destino.” - Carl Jung."
          }}
        </cite>
      </p>

      <p class="parrafo-justificado">
        {{
          $i18n.locale === "en"
            ? "I offer personalized spiritual tarot readings for you. I usually vary the type of cards (tarot and/or oracle cards), adapting to the needs of your current situation. Together, we will delve into your subconscious with images and messages that will bring you light. We will understand the spiritual lessons of your life in your present moment, giving you the confidence to understand your past and influence your future."
            : "Te ofrezco un tarot espiritual individualizado para ti. Suelo variar el tipo de cartas (cartas de tarot y/o de oráculo) adaptándome a la necesidad de tu situación actual. Nos sumergiremos juntos en tu subconsciente con imágenes y mensajes que te aportarán luz. Comprenderemos las lecciones espirituales de tu vida en tu momento presente, dándote la confianza de entender tu pasado e influenciar tu futuro."
        }}
      </p>

      <p class="resalte">
        {{
          $i18n.locale === "en"
            ? "Magic, surprise, and fun!"
            : "¡Magia, sorpresa y diversión!"
        }}
      </p>

      <template v-if="$i18n.locale === 'es'">
        <p>Podrás elegir tu tema principal para la sesión:</p>
        <ul>
          <li>Finanzas</li>
          <li>Carrera profesional</li>
          <li>Amor</li>
          <li>Relaciones</li>
          <li>Salud</li>
          <li>Guía general, dejando que el universo te sorprenda.</li>
        </ul>
      </template>
      <template v-else>
        <p>You can choose your main topic for the session:</p>
        <ul>
          <li>Finances</li>
          <li>Career</li>
          <li>Love</li>
          <li>Relationships</li>
          <li>Health</li>
          <li>General guidance, letting the universe surprise you!</li>
        </ul>
      </template>

      <p class="resalte m-0 mt-5">{{ $t("messages.prices") }}</p>
      <p class="resalteSoft">
        <span>{{ $t("messages.iSupportYou") }} - {{ getMoneda }}35</span>
        <span>{{ $t("messages.iAmGenerous") }} - {{ getMoneda }}45</span>
        <span>{{ $t("messages.iLiveInAbundance") }} - {{ getMoneda }}50</span>
        <span class="mt-5">{{ $t("messages.disclimerPago") }}</span>
        <span>{{ $t("messages.confidencialidad") }}</span>
      </p>

      <p class="disclaime" v-if="$i18n.locale === 'es'">
        Las sesiones podrán realizarse online o de forma presencial. Este
        servicio se presta en español e inglés.
      </p>
      <p class="disclaime" v-else>
        The sessions can be done online or in person. This service is provided
        in Spanish and English.
      </p>
    </div>

    <!-- registros -->

    <div v-if="sesion === 'registros'">
      <h1 class="title-vista">{{ $t("messages.registrosTitle") }}</h1>
      <p class="parrafo-justificado">
        {{
          $i18n.locale === "en"
            ? "By opening the Akashic Records, you can access your soul’s memory. Akasha is a quantum energy field that contains information about who you are, who you've been, and who you can become. These records are a right granted by divine power. They are protected by guardians who are your spiritual family (light beings, ascended masters, and ancestors) and offer wisdom and knowledge to help you expand your perspectives and make better decisions."
            : "Al abrir los Registros Akáshicos, puedes acceder a la memoria de tu alma. Akasha es un campo de energía cuántica que contiene información sobre quién eres, quién has sido y quién puedes llegar a ser. Estos registros son un derecho otorgado por un poder divino. Están protegidos por guardianes que son tu familia espiritual (seres de luz, maestros ascendidos y ancestros) y ofrecen sabiduría y conocimiento para ayudarte a ampliar tus perspectivas y tomar mejores decisiones."
        }}
      </p>

      <p class="parrafo-justificado">
        {{
          $i18n.locale === "en"
            ? "Akashic Records therapy provides knowledge about your soul, your mission, and purpose, what you've come to learn. Through this therapy, you can ask questions about your current situation, past experiences, and possibilities for the future. It is not a process of divination. With your permission, I will open your Akashic Records to answer your questions and provide guidance. Before the sessions, I will give you advice and direction to ensure that the experience is as accurate as possible."
            : "La terapia de Registros Akáshicos proporciona conocimiento sobre tu alma, tu misión y lección de vida. A través de esta terapia, puedes hacer preguntas sobre tu situación actual, experiencias pasadas y posibilidades para el futuro. No es un proceso de adivinación. Con tu permiso, abriré tus Registros Akáshicos para responder a tus preguntas y brindarte orientación. Antes de las sesiones, te daré consejo y guía para asegurar que la experiencia sea lo más acertada posible."
        }}
      </p>

      <template v-if="$i18n.locale === 'es'">
        <p class="parrafo-justificado">
          Puedes elegir varios tipos de sesiones de apertura de los Registros
          Akáshicos:
        </p>
        <ul>
          <li class="parrafo-justificado">
            Carta de tu alma (las características de tu alma: color de tu alma,
            animal de poder o espiritual, guia espiritual…).
          </li>
          <li class="parrafo-justificado">Escaneo y equilibrio de chakras.</li>
          <li class="parrafo-justificado">
            Carga, estabilización, sanación y protección del aura.
          </li>
          <li class="parrafo-justificado">
            Limpieza energética de bienes y espacios físicos.
          </li>
          <li class="parrafo-justificado">Manifestación desde Akasha.</li>
          <li class="parrafo-justificado">
            Sanaciones:
            <ul>
              <li class="parrafo-justificado">Salud física</li>
              <li class="parrafo-justificado">Salud emocional</li>
              <li class="parrafo-justificado">Alma</li>
              <li class="parrafo-justificado">Ancestros</li>
              <li class="parrafo-justificado">Niño interior</li>
              <li class="parrafo-justificado">Relaciones</li>
              <li class="parrafo-justificado">Relaciones kármicas</li>
              <li class="parrafo-justificado">Vidas pasadas</li>
              <li class="parrafo-justificado">
                Educación de niños y adolescentes
              </li>
              <li class="parrafo-justificado">Finanzas</li>
              <li class="parrafo-justificado">Carrera profesional</li>
              <li class="parrafo-justificado">Fertilidad y salud del útero</li>
              <li class="parrafo-justificado">Mascotas</li>
            </ul>
          </li>
        </ul>
      </template>
      <template v-else>
        <p class="parrafo-justificado">
          You can choose from various types of Akashic Records opening sessions:
        </p>
        <ul>
          <li>
            Letter from your soul (characteristics of your soul: soul color,
            power animal or spiritual animal, spiritual guide...).
          </li>
          <li>Scanning and balancing of chakras.</li>
          <li>Charging, stabilizing, healing, and protecting the aura.</li>
          <li>Energetic cleansing of belongings and physical spaces.</li>
          <li>Manifestation from Akasha.</li>
          <li>
            Healings:
            <ul>
              <li class="parrafo-justificado">Physical health</li>
              <li class="parrafo-justificado">Emotional health</li>
              <li class="parrafo-justificado">Soul</li>
              <li class="parrafo-justificado">Ancestors</li>
              <li class="parrafo-justificado">Inner child</li>
              <li class="parrafo-justificado">Relationships</li>
              <li class="parrafo-justificado">Karmic relationships</li>
              <li class="parrafo-justificado">Past lives</li>
              <li class="parrafo-justificado">
                Education of children and teenagers
              </li>
              <li class="parrafo-justificado">Finances</li>
              <li class="parrafo-justificado">Professional career</li>
              <li class="parrafo-justificado">Fertility and womb health</li>
              <li class="parrafo-justificado">Pets</li>
            </ul>
          </li>
        </ul>
      </template>
      <p class="resalte m-0 mt-5">{{ $t("messages.prices") }}</p>
      <p class="resalteSoft">
        <span>{{ $t("messages.iSupportYou") }} - {{ getMoneda }}35</span>
        <span>{{ $t("messages.iAmGenerous") }} - {{ getMoneda }}45</span>
        <span>{{ $t("messages.iLiveInAbundance") }} - {{ getMoneda }}50</span>
        <span class="mt-5">{{ $t("messages.disclimerPago") }}</span>
        <span>{{ $t("messages.confidencialidad") }}</span>
      </p>

      <p class="disclaime" v-if="$i18n.locale === 'es'">
        Las sesiones podrán realizarse online o de forma presencial. Este
        servicio se presta actualmente sólo en español.
      </p>
      <p class="disclaime" v-else>
        The sessions can be conducted online or in person. This service is
        currently only available in Spanish.
      </p>
    </div>

    <!-- meditacion -->

    <div v-if="sesion === 'meditacion'">
      <h1 class="title-vista">{{ $t("messages.meditationTitle") }}</h1>
      <p class="parrafo-justificado" v-html="formattedTextFirstPart"></p>
      <p class="parrafo-justificado">
        <cite>
          {{
            $i18n.locale === "en"
              ? "“Meditation refers to a family of techniques which have in common a conscious attempt to focus attention in a non-analytical way and an attempt not to dwell on discursive, ruminating thought.” (Shapiro, 1982)"
              : "“La meditación se refiere a una familia de técnicas que tienen en común un intento consciente de centrar la atención de una manera no analítica y un intento de no detenerse en el pensamiento discursivo y rumiante” (Shapiro, 1982)"
          }}
        </cite>
      </p>
      <p class="parrafo-justificado">
        <cite>
          {{
            $i18n.locale === "en"
              ? "“A universal theology is impossible, but a universal experience is not only possible but necessary.” - A Course In Miracles"
              : "“Una teología universal es imposible, mientras que una experiencia universal no sólo es posible sino necesaria.” - Un Curso de Milagros"
          }}
        </cite>
      </p>
      <div
        class="descripcion-detalle"
        v-for="(descarga, index) in descargas.filter(
          (item) => item.titulo[$i18n.locale]
        )"
        :key="index"
      >
        <h2 style="color: white">
          {{ descarga.titulo[$i18n.locale] }}
        </h2>
        <p
          class="parrafo-justificado text-meditacion"
          style="color: white"
          v-for="(parrafo, index2) in descarga.parrafos[$i18n.locale]"
          :key="index2"
        >
          {{ parrafo }}
        </p>
        <a
          :href="`../../assets/sounds/${descarga.fichero[$i18n.locale]}`"
          :download="descarga.fichero[$i18n.locale]"
        >
          <h3 class="link-audio">
            <b-icon icon="download" scale="1" class="ml-3 mr-3"></b-icon
            >{{ descarga.descargaTitulo[$i18n.locale] }}
          </h3>
        </a>
      </div>

      <!-- <div class="descripcion-detalle">
        <h2 style="color: white">
          {{
            $i18n.locale === "en"
              ? "Unconditional Love Meditation"
              : "Meditación del amor incondicional"
          }}
        </h2>
        <p class="parrafo-justificado" style="color: white">
          {{
            $i18n.locale === "en"
              ? "The Metta Bhavana meditation, also known as the Unconditional Love Meditation, originates from ancient India and is derived from the Pali language. The term “metta” can be translated to “loving-kindness” or “unconditional love”, referring to pure love that extends beyond romantic love to include all living beings. “Bhavana” translates to “cultivate” or “develop”"
              : "La meditación Metta Bhavana, también conocida como Meditación del Amor Incondicional, tiene su origen en la antigua India y proviene del idioma Pali. El término “metta” se puede traducir como “amor bondadoso” o “amor incondicional”, refiriéndose a un amor puro que se extiende más allá del amor romántico e incluye a todos los seres vivos. “Bhavana” se traduce como “cultivar” o “desarrollar”."
          }}
        </p>
        <p class="parrafo-justificado" style="color: white">
          {{
            $i18n.locale === "en"
              ? "This meditation is different from mindfulness meditation in that it has a specific goal, which is to cultivate loving-kindness and feel love for oneself and others. It involves reprogramming one's emotions to be more love-based, rather than just observing the mind without judgment. Practicing this meditation raises one's awareness of their automatic emotional state, which may include judgment or resentment. By practicing this meditation, one can make the decision to achieve a higher state of consciousness and attain happiness."
              : "Esta meditación difiere de la meditación “mindfulness” en que tiene un objetivo específico, que es cultivar el amor bondadoso y sentir amor por uno mismo y por los demás. Implica reprogramar las emociones para que estén basadas en el amor, en lugar de simplemente observar la mente sin juzgar. Practicando esta meditación, se aumenta la conciencia del propio estado emocional automático, que puede incluir juicio o resentimiento. Al practicar esta meditación, puedes tomar la decisión de alcanzar un estado superior de consciencia y alcanzar la felicidad."
          }}
        </p>
        <h3 @click="downloadAudio()" class="link-audio">
          <b-icon icon="download" scale="1" class="ml-3 mr-3"></b-icon
          >{{ $t("messages.download") }}
        </h3>
      </div> -->
      <!-- <p class="resalteSoft">
        Donación XXXXX Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Cupiditate aliquid laudantium in tempora dolorem
      </p> -->
    </div>
    <modal-add-cite-of-session
      :show="showModalAdd"
      :tiposSession="tiposSession"
      :sesionistas="sesionistas"
      :husoHorarios="husoHorarios"
      :sesionistasTipoSesion="sesionistasTipoSesion"
      :disponibilidadSesionistas="disponibilidadSesionistas"
      @ok="cerrarModal()"
      @cancel="cerrarModal()"
    />
  </div>
</template>

<script>
import ModalAddCiteOfSession from "@/components/PublicComponents/ModalAddCiteOfSession.vue";
import SesionesService from "@/services/sesiones.service.js";
import EventBus from "@/services/eventBus.js";
import { mapGetters } from "vuex";
export default {
  name: "SesionDetail",
  components: { "modal-add-cite-of-session": ModalAddCiteOfSession },
  data() {
    return {
      sesion: "",
      sesionesPosibles: ["tarot", "registros", "meditacion"],
      showModalAdd: false,
      sessionesService: new SesionesService(),
      tiposSession: [],
      sesionistas: [],
      husoHorarios: [],
      sesionistasTipoSesion: [],
      disponibilidadSesionistas: [],
      descargas: [],
    };
  },
  mounted() {
    this.sesion = this.$route.params.sesion.toLowerCase();
    if (!this.sesionesPosibles.includes(this.sesion)) {
      this.$router.push({ name: "Home" });
    }
    this.getDescargas();
    this.getTipoSesiones();
    this.getSesionistas();
    this.getSesionistasTipoDeSesion();
    if (this.getToken) {
      this.getHusoHorarios();
      this.getDisponibilidadSesionistas();
    }
    window.scrollTo(0, 0);
  },
  watch: {
    $route(to, from) {
      this.sesion = to.params.sesion.toLowerCase();
      if (!this.sesionesPosibles.includes(this.sesion)) {
        this.$router.push({ name: "Home" });
      }
    },
    getToken() {
      if (this.getToken) {
        this.getTipoSesiones();
        this.getSesionistas();
        this.getHusoHorarios();
        this.getSesionistasTipoDeSesion();
        this.getDisponibilidadSesionistas();
      }
    },
    sesion() {
      window.scrollTo(0, 0);
    },
  },
  methods: {
    getDescargas() {
      this.descargas = require(`@/assets/data/descargas.json`);
    },
    cerrarModal() {
      this.showModalAdd = false;
    },
    showModal() {
      if (!this.getToken) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message:  this.$t("messages.mustBeLogin"),
        });
        return;
      }
      this.showModalAdd = true;
    },
    getTipoSesiones() {
      this.sessionesService
        .getTipoSesiones()
        .then((response) => {
          this.tiposSession = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getSesionistas() {
      this.sessionesService
        .getSesionistas()
        .then((response) => {
          this.sesionistas = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getHusoHorarios() {
      this.sessionesService
        .getHusoHorarios()
        .then((response) => {
          this.husoHorarios = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getSesionistasTipoDeSesion() {
      this.sessionesService
        .getSesionistasTipoSesion()
        .then((response) => {
          this.sesionistasTipoSesion = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDisponibilidadSesionistas() {
      this.sessionesService
        .getDisponibilidadSesionistas()
        .then((response) => {
          this.disponibilidadSesionistas = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    ...mapGetters(["getToken"]),
    getWidth() {
      return window.innerWidth;
    },
    getMoneda() {
      const zona = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return zona.includes("London")
        ? "£"
        : zona.includes("Europe")
        ? "€"
        : "$";
    },
    idiomas() {
      if (this.sesionistas.length === 0) return "";

      const sesionActual = this.tiposSession.find((tipoSesion) => {
        return tipoSesion.codigo === this.sesion;
      });

      if (!sesionActual) return "";

      const sesionistasEnEsaSesion = this.sesionistasTipoSesion.filter(
        (sesionistasTipoSesion) =>
          sesionistasTipoSesion.id_tipo_sesion === sesionActual.id
      );

      const getIdSesionistasNoRepeat = [
        ...new Set(
          sesionistasEnEsaSesion.map((sesionista) => sesionista.id_sesionista)
        ),
      ];

      const getIdiomas = this.sesionistas
        .filter((sesionista) =>
          getIdSesionistasNoRepeat.includes(sesionista.id)
        )
        .map((sesionista) => sesionista.idiomas.split(","));

      let idiomaArrayPlain = getIdiomas.flat();
      let idiomasTraducidos = idiomaArrayPlain.map((idioma) => {
        return (
          this.$t(`messages.${idioma}`).charAt(0).toUpperCase() +
          this.$t(`messages.${idioma}`).slice(1)
        );
      });

      return idiomasTraducidos.join(", ");
    },
    formattedTextFirstPart() {
      return this.$i18n.locale === "en"
        ? `All of our experiences occur in the present moment. However, we often become consumed by worries about the future or sadness about the past, mistakenly believing that these thoughts and feelings define us. We may listen incessantly to the inner dialogue in our minds or be overwhelmed by our emotions, which prevents us from recognizing our true nature as beings of light and love.
            <br><br>
            There are different meditation techniques that help us connect with our true nature. Some meditation techniques will ask you to move and be active. Others will ask you to stay in a quiet and still place. There are also meditation techniques where you are asked to focus on your emotions and thoughts, and others that ask you to detach from them. The diversity of techniques is like life itself, with a great multitude of forms. This makes meditation very interesting and adaptable to your preferences.
            <br><br>
            It is very important not to give up and instead find your way of meditating. The benefits of meditation are key to living in the present and to having a harmonious, fulfilling, and free-from-negative programming (limiting beliefs) life.
            <br><br>
            All techniques are good, and you will choose the one that is most suitable for you. As long as the practice is a conscious attempt to focus your attention on a particular object and do so in a non-analytical way, you can have that wonderful experience of the present moment.`
        : `Todas nuestras experiencias ocurren en el momento presente. Sin embargo, a menudo nos dejamos consumir por preocupaciones sobre el futuro o tristezas por el pasado, creyendo erróneamente que estos pensamientos y sentimientos nos definen. Podemos escuchar sin descanso el diálogo interno en nuestras mentes o ser abrumados por nuestras emociones, lo que nos impide reconocer nuestra verdadera naturaleza como seres de luz y amor.
            <br><br>
            Existen distintas técnicas de meditación que nos ayudan a conectarnos con nuestra verdadera naturaleza. Algunas técnicas de meditación te pedirán moverte y estar activo. Otras, en cambio, te pedirán quedarte en un lugar tranquilo y quieto. También existen técnicas de meditación en las cuales se te pedirá centrarte en tus emociones y pensamientos, y otras que te pedirán lo contrario, desapegarte de ellos. La diversidad de técnicas es como la vida misma, con una gran multitud de formas. Este hecho hace a la meditación muy interesante y adaptable a tus preferencias.
            <br><br>
            Es muy importante no rendirse y, en cambio, buscar tu forma de hacer meditación. Los beneficios de la meditación son clave para vivir en el presente y para tener una vida en armonía, plena y libre de programaciones negativas (creencias limitantes).
            <br><br>
            Todas las técnicas son buenas, y tú elegirás cuál es la más adecuada para ti. Siempre que la práctica sea un intento consciente de focalizar tu atención en un objeto en particular y hacerlo de una manera no analítica, podrás tener esa experiencia maravillosa del momento presente.`;
    }
  },
};
</script>

<style lang="scss" scoped>
.contenedor {
  padding: 50px 300px;
  width: 100%;
}

p {
  font-size: 1.4rem;
  margin-top: 50px;
  text-align: left;
}

cite {
  font-size: 1.5rem;
  font-weight: bold;
}

.resalte {
  font-size: 1.7rem;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #eb1090;
  text-align: center;
}

.resalteSoft {
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
  //last child
  span:last-child {
    margin-top: 30px;
  }
}

li {
  font-size: 1.4rem;
  text-align: left;
}

.link-audio {
  cursor: pointer;
  color: var(--primary-misty-oracle);
  transition: 0.5s all;
  margin-top: 50px;
}

.link-audio:hover {
  color: white;
  text-shadow: 0px 0px 10px var(--primary-misty-oracle);
  letter-spacing: 1px;
}

.div-flotante {
  position: fixed;
  bottom: 50%;
  right: 0;
  margin: 20px;
  z-index: 100;
}

.div-flotante-in-title {
  float: right;
  display: none;
}
.descripcion-detalle {
  background-color: #0b0144;
  color: white;
  padding: 54px;
  border: 4px solid #eb1090;
  margin-top: 67px;
}

h2 {
  font-size: 2.5vw;
}

.container-div-flotante-in-title {
  display: none;
}

.disclaime {
  font-size: 1rem;
  letter-spacing: 1.2px;
}

.parrafo-justificado {
  text-align: justify;
}

@media screen and (max-width: 1100px) {
  .div-flotante {
    display: none;
  }

  .container-div-flotante-in-title {
    display: block;
  }

  .div-flotante-in-title {
    display: block;
  }
  .contenedor {
    padding: 50px 15px;
  }

  .title {
    font-size: 6vw !important;
  }

  p {
    font-size: 1.2rem;
    margin-top: 30px;
  }

  li {
    font-size: 1.2rem;
  }

  cite {
    font-size: 1.3rem;
  }

  .resalte {
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .resalteSoft {
    font-size: 1.5rem;
  }

  .descripcion-detalle {
    background-color: #0b0144;
    color: white;
    padding: 10px;
    border: 4px solid #eb1090;
    margin-top: 67px;
    h2 {
      font-size: 5vw;
    }
  }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 10vw !important;
  }

  .descripcion-detalle {
    h2 {
      font-size: 8vw;
    }
  }
  
  .text-meditacion{
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
}
</style>
