import axios from "axios";

export default class SesionesService {

  getHusoHorarios(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "huso-horarios")
  }

  getTipoSesiones(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "tipo-sesiones")
  }

  getSesionistas(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "sesionistas")
  }

  getSesionistasTipoSesion(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "sesionista-tipos-sesion")
  }
  
  getDisponibilidadSesionistas(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "disponibilidad-sesionistas")
  }

  //todos los agendamientos de la canalizadora
  getAllAgendamientos(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "agendamientos/canalizadora")
  }

  //agendamiento por id
  getAgendamientoByIdAdmin(id){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "agendamientos/admin/"+id)
  }

  getAgendamientoByIdCanalizadora(id){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "agendamientos/canalizadora/"+id)
  }

  updateFechaHoraAgendamiento(payload){
    return axios.put(process.env.VUE_APP_API_PUBLIC + "agendamiento/admin/edit-fecha-hora",payload)
  }

  updateAgendamiento(payload){
    return axios.put(process.env.VUE_APP_API_PUBLIC + "agendamiento/admin/edit",payload)
  } 

}
