<template>
  <b-modal id="modal-agendar-sesion" ref="modal-agendar-sesion" size="lg"
    :title="$t('messages.scheduleSession').toUpperCase()" scrollable header-bg-variant="primary"
    header-text-variant="light" footer-bg-variant="gray" style="color: white !important" no-close-on-backdrop
    no-close-on-esc @hidden="resetModal">
    <div class="container-agregables">
      <template>
        <vue-step-progress-indicator :steps="[
          $t('messages.typeOfSession'),
          $t('messages.language'),
          $t('messages.channelizer'),
          $t('messages.dateAndTime'),
          $t('messages.summary'),
        ]" :active-step="activeStep" :is-reactive="false" @onStepChanged="onStepChanged"
          @onEnterFinalStep="onEnterFinalStep" style="justify-content: space-around" :colors="colorData" />
        <div class="row mt-5">
          <!-- <div class="col-3 col-md-3 d-flex">
            <b-button
              variant="outline-primary"
              v-show="activeStep > 0"
              @click="backStep"
              :disabled="inProcess"
              ><div style="display: flex">
                <span class="botonAdelanteAtras">{{ "<<<" }}</span
                ><span class="mesage-guide">{{ $t("messages.back") }}</span>
              </div></b-button
            >
          </div> -->
          <div class="col-12 container-titulo">
            <span class="font-weight-bold border-bottom titulo">
              {{ getTitle }}
            </span>
          </div>
          <!-- <div class="col-3 col-md-3 d-flex justify-content-end">
            <b-button
              variant="outline-primary"
              v-show="activeStep < 4"
              @click="nextStep"
              :disabled="inProcess"
            >
              <div style="display: flex">
                <span class="mesage-guide">{{ $t("messages.next") }}</span
                ><span class="botonAdelanteAtras">{{ ">>>" }}</span>
              </div>
            </b-button>
          </div> -->
        </div>
        <div class="container-step" v-show="activeStep == 0">
          <div class="row">
            <div class="col-12 contenedor-seccion" style="flex-direction: column">
              <b-form-select v-model="selectedTipoSesion" :options="optionsTipoSesion" color="primary"></b-form-select>
              <div class="container-descripcion-sesion">
                <p>{{ $t("messages.description") }}:</p>
                {{ getDescripcionTraslated }}
              </div>
            </div>
          </div>
        </div>
        <div class="container-step" v-show="activeStep == 1">
          <div class="row">
            <div class="col-12 contenedor-seccion">
              <b-form-select v-model="idiomaSeleccionado" :options="optionsIdiomas"></b-form-select>
            </div>
          </div>
        </div>
        <div class="container-step" v-show="activeStep == 2">
          <div class="row">
            <div class="col-12 contenedor-seccion" style="flex-direction: column;">
              <b-form-group :label="$t('messages.channeler')" label-for="sesionista" style="width: 100%;">
                <b-form-select id="sesionista" v-model="selectedSesionista" :options="optionsSesionistas"
                  style="width: 100%;"></b-form-select>
              </b-form-group>
              <b-form-group :label="$t('messages.location')" label-for="location" style="width: 100%;">
                <b-form-select id="location" v-model="selectedLocation" :options="localizacionOpciones"
                  style="width: 100%;"></b-form-select>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="container-step" v-show="activeStep == 3">
          <div class="row">
            <div class="col-md-6 container-calendar">
              <div style="min-height: 20px;">
                <span> {{ dateFormated }}</span>
              </div>
              <b-calendar hide-header v-model="fechaSeleccionada" :date-disabled-fn="dateDisabled"
                :locale="this.$i18n.locale" nav-button-variant="primary" style="color: red;"
                v-bind="labels[this.$i18n.locale] || {}"></b-calendar>
              <span style="font-size: 0.8rem; margin-top: 10px">{{ $t("messages.yourTimezoneIs") }}: {{ zona_horaria
              }}</span>
            </div>
            <div class="col-md-6 container-hour">
              <label for="selectorHoras" v-if="fechaSeleccionada">{{
                $t("messages.localTimeOfSessionStart")
              }}</label>
              <b-form-select id="selectorHoras" name="selectorHoras" v-model="horaSeleccionada"
                :options="horasDisponibles" v-if="fechaSeleccionada"></b-form-select>
              <span style="margin-top: 30px" v-if="horaSesionista">{{
                `${$t("messages.timeOfChanneler")} (${getHusoHorario ? getHusoHorario.ciudad : ""
                  }): ${horaSesionista}`
              }}</span>
            </div>
          </div>
        </div>
        <div class="container-step" v-show="activeStep == 4">
          <div class="row">
            <div class="col-12 contenedor-seccion" style="flex-direction: column; font-size: 1.3rem">
              <p class="container-info">
                <span>{{ $t("messages.session") }}</span>
                <strong>{{
                  getSesionSelected ? this.allFirstLettersUpperCase(getSesionSelected.nombre) : ""
                }}</strong>
              </p>
              <p class="container-info">
                <span>{{ $t("messages.maximumTime") }}</span>
                <strong>{{
                  getSesionSelected ? getSesionSelected.duracion : ""
                }}
                  min</strong>
              </p>
              <p class="container-info">
                <span>{{ $t("messages.channeler") }}</span>
                <strong>{{
                  `${getSesionista ? this.allFirstLettersUpperCase(getSesionista.nombre) : ""} ${getSesionista ? this.allFirstLettersUpperCase(getSesionista.apellido) : ""
                  }`
                }}</strong>
              </p>
              <p class="container-info">
                <span>{{ $t("messages.dateLocalTime") }}</span>
                <strong>{{
                  this.firstLetterToUpperCase(`${$moment(fechaSeleccionada).format(
                    "dddd, DD-MM-YYYY"
                  )} ${secondsToHM(horaSeleccionada)}`)
                }}</strong>
              </p>
              <p class="container-info">
                <span>{{ $t("messages.dateAndTimeOfTheChanneler") }}</span>
                <strong>{{
                  this.firstLetterToUpperCase(`${fechaHoraSesionista} (${$t("messages.timeOf")} ${getHusoHorario ?
                    getHusoHorario.ciudad : ""
                    })`)
                }}
                </strong>
              </p>
            </div>
          </div>
        </div>
      </template>
      <!-- <b-modal header-bg-variant="primary" header-text-variant="light" id="notificacionModalCitaSesion" hide-footer>
        <template #modal-title> {{ $t("messages.successSchedule") }} </template>
        <div class="d-block text-center">
          <h5>
            Su cita se ha generado satisfactoriamente, te esperamos el dia XXXX
            a la hora XXXX
          </h5>
        </div>
        <b-button class="mt-3" block @click="$bvModal.hide('notificacionModalCitaSesion')">{{ $t("messages.close")
        }}</b-button>
      </b-modal> -->
    </div>
    <template #modal-footer>
      <div style="display: flex;justify-content: space-between;width: 100%;">
        <div>
          <b-button variant="outline-primary" v-show="activeStep > 0" @click="backStep" :disabled="inProcess">
            <div style="display: flex">
              <span class="botonAdelanteAtras">{{ "<<<" }}</span><span class="mesage-guide">{{ $t("messages.back")
              }}</span>
            </div>
          </b-button>
        </div>
        <div>
          <b-button variant="secondary" size="sm" class="mr-2" @click="cancel($event)">
            {{ $t("messages.cancel") }}
          </b-button>
          <b-button variant="primary" size="sm" class="mr-4" @click="handleOk($event)" v-show="activeStep == 4">
            {{ $t("messages.save") }}
          </b-button>
          <b-button variant="outline-primary" v-show="activeStep < 4" @click="nextStep" :disabled="inProcess">
            <div style="display: flex">
              <span class="mesage-guide">{{ $t("messages.next") }}</span><span class="botonAdelanteAtras">{{ ">>>"
              }}</span>
            </div>
          </b-button>
        </div>
      </div>

    </template>
  </b-modal>
</template>

<script>
import EventBus from "@/services/eventBus.js";
import { Helpers } from "@/mixins/Helpers";
import VueStepProgressIndicator from "vue-step-progress-indicator";
import CalendarioService from "@/services/calendario.service";
import { mapGetters } from "vuex";

export default {
  name: "ModalAddCiteOfSession",
  components: {
    VueStepProgressIndicator: VueStepProgressIndicator,
  },
  props: {
    show: Boolean,
    tiposSession: Array,
    sesionistas: Array,
    husoHorarios: Array,
    sesionistasTipoSesion: Array,
    disponibilidadSesionistas: Array,
  },
  mixins: [Helpers],
  data() {
    return {
      activeStep: 0,
      fechaSeleccionada: null,
      horaSeleccionada: null,
      horasDisponibles: [],
      selectedTipoSesion: null,
      selectedSesionista: null,
      inProcess: false,
      diasDisponibles: [],
      optionsSesionistas: [],
      colorData: {
        progress__bubble: {
          active: {
            color: "#fff",
            backgroundColor: "var(--primary-misty-oracle)",
            borderColor: "var(--primary-misty-oracle)",
          },
          inactive: {
            color: "#fff",
            backgroundColor: "#34495e",
            borderColor: "#34495e",
          },
          completed: {
            color: "#fff",
            borderColor: "var(--secondary-misty-oracle)",
            backgroundColor: "var(--secondary-misty-oracle)",
          },
        },
        progress__label: {
          active: {
            color: "var(--primary-misty-oracle)",
          },
          inactive: {
            color: "#34495e",
          },
          completed: {
            color: "var(--secondary-misty-oracle)",
          },
        },
        progress__bridge: {
          active: {
            backgroundColor: "#e74c3c",
          },
          inactive: {
            backgroundColor: "#34495e",
          },
          completed: {
            backgroundColor: "#27ae60",
          },
        },
      },
      calendarioService: new CalendarioService(),
      zona_horaria: Intl.DateTimeFormat().resolvedOptions().timeZone,
      idiomaSeleccionado: null,
      optionsIdiomas: [],
      agendamientosDelUsuario: [],
      selectedLocation: null,
      labels: {
        es: {
          labelPrevDecade: 'Década anterior',
          labelPrevYear: 'Año anterior',
          labelPrevMonth: 'Mes anterior',
          labelCurrentMonth: 'Mes actual',
          labelNextMonth: 'Mes siguiente',
          labelNextYear: 'Año siguiente',
          labelNextDecade: 'Década siguiente',
          labelToday: 'Hoy',
          labelSelected: 'Fecha seleccionada',
          labelNoDateSelected: 'No hay fecha seleccionada',
          labelCalendar: 'Calendario',
          labelNav: 'Navegación del calendario',
          labelHelp: 'Presione la tecla de flecha hacia abajo para acceder al selector de fecha.'
        }
      }
    };
  },
  methods: {
    async getAgendamientosUsuario() {
      try {
        const response =
          await this.calendarioService.getAgendamientosByIdUsuario();
        this.agendamientosDelUsuario = response.data.data;
        this.agendamientosDelUsuario.forEach((agendamiento) => {
          agendamiento["duracion"] = this.getSesionById(
            agendamiento.id_tipo_sesion
          ).duracion;
        });
      } catch (error) {
        this.agendamientosDelUsuario = [];
      }
    },
    getSesionById(id) {
      return this.tiposSession.find((tipoSesion) => tipoSesion.id == id);
    },
    async getAgendamientos() {
      try {
        const response = await this.calendarioService.getAgendamientosBySelect(
          this.selectedSesionista
        );
        this.agendamientos = response.data.data;
      } catch (error) {
        this.agendamientos = [];
      }
    },
    dateDisabled(ymd, date) {
      return !this.diasDisponibles.includes(ymd);
    },
    async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      try {
        const data = {
          id_sesionista: this.selectedSesionista,
          dia: +this.fechaSeleccionada.split("-")[2],
          mes: +this.fechaSeleccionada.split("-")[1],
          anio: +this.fechaSeleccionada.split("-")[0],
          hora: this.horaSeleccionada,
          id_tipo_sesion: this.selectedTipoSesion,
          comentario: "",
          zona_horaria: this.zona_horaria,
          idioma: this.idiomaSeleccionado,
          locacion: this.selectedLocation,
        };
        const response = await this.calendarioService.crearAgendamiento(data);
        if (response.data.status == 200) {
          this.getAgendamientosUsuario();
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "",
            variant: "success",
            message: this.$t("messages.appointmentCreated"),
          });
          this.$emit("ok");
          this.$forceUpdate();
        } else {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: this.$t("messages.appointmentCreatedError"),
          });
        }
      } catch (error) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "danger",
          message: this.$t("messages.errorCreatingAppointment"),
        });
      }
    },
    cancel(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$emit("cancel");
      this.$forceUpdate();
    },
    onStepChanged(evento) {
      this.activeStep = evento;
    },
    onEnterFinalStep(evento) {
      console.log("paso final", evento);
    },
    backStep() {
      this.activeStep--;
    },
    nextStep() {
      if (this.activeStep == 0 && !this.selectedTipoSesion) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: this.$t("messages.selectSessionType"),
        });
        return false;
      }
      if (this.activeStep == 1 && !this.idiomaSeleccionado) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: this.$t("messages.selectLanguage"),
        });
        return false;
      }
      if (this.activeStep == 2 && !this.selectedSesionista) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: this.$t("messages.selectSessionist"),
        });
        return false;
      }
      if (this.activeStep == 2 && !this.selectedLocation) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: this.$t("messages.selectLocation"),
        });
        return false;
      }
      if (
        this.activeStep == 3 &&
        (!this.fechaSeleccionada || !this.horaSeleccionada)
      ) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: this.$t("messages.selectDateAndTime"),
        });
        return false;
      }

      if (this.activeStep == 3) {
        let sesion = this.tiposSession.find((tipoSesion) => tipoSesion.id == this.selectedTipoSesion)
        let rangoDisponible = this.disponibilidadSesionistas.find((disponibilidad) => {
          return disponibilidad.id_sesionista === this.selectedSesionista && disponibilidad.dia_semana === this.diaSemanaSesionista && disponibilidad.desde <= this.HMToSeconds(this.horaSesionista) && disponibilidad.hasta >= this.HMToSeconds(this.horaSesionista) && disponibilidad.codigo_sesion === sesion.codigo
        })
        if (!rangoDisponible) {
          let rangoDisponibleSesionista = this.disponibilidadSesionistas.find((disponibilidad) => {
            return disponibilidad.id_sesionista === this.selectedSesionista && disponibilidad.dia_semana === this.diaSemanaSesionista && disponibilidad.codigo_sesion === sesion.codigo
          })
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "warning",
            message: this.$t("messages.timeOutOfRange", { time: `${this.secondsToHM(rangoDisponibleSesionista.desde)} - ${this.secondsToHM(rangoDisponibleSesionista.hasta)}` }),
          });
          return false;
        }
      }

      if (this.activeStep == 0 && this.optionsIdiomas.length == 1 && this.idiomaSeleccionado == null) {
        this.idiomaSeleccionado = this.optionsIdiomas[0].value
      }

      if (this.activeStep == 1 && this.optionsSesionistas.length == 1 && this.selectedSesionista == null) {
        this.selectedSesionista = this.optionsSesionistas[0].value
      }

      this.activeStep++;
    },
    resetModal(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.activeStep = 0;
      this.fechaSeleccionada = null;
      this.horaSeleccionada = null;
      this.selectedTipoSesion = null;
      this.selectedSesionista = null;
      this.inProcess = false;
      this.diasDisponibles = [];
      this.optionsSesionistas = [];
      this.$emit("cancel");
      this.$forceUpdate();
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.$refs["modal-agendar-sesion"].show();
        this.$forceUpdate();
      } else {
        this.$bvModal.hide("modal-agendar-sesion");
      }
    },
    selectedTipoSesion(newValue) {
      this.selectedSesionista = null;
      this.fechaSeleccionada = null;
      this.horaSeleccionada = null;
      this.optionsSesionistas = [];
      this.idiomaSeleccionado = null;
      this.optionsIdiomas = [];
      if (newValue) {
        if (this.sesionistas.length === 0) return;
        const sesionistasEnEsaSesion = this.sesionistasTipoSesion.filter(
          (sesionistasTipoSesion) =>
            sesionistasTipoSesion.id_tipo_sesion === this.selectedTipoSesion
        );

        const getIdSesionistasNoRepeat = [
          ...new Set(
            sesionistasEnEsaSesion.map((sesionista) => sesionista.id_sesionista)
          ),
        ];
        const getIdiomas = this.sesionistas
          .filter((sesionista) =>
            getIdSesionistasNoRepeat.includes(sesionista.id)
          )
          .map((sesionista) => sesionista.idiomas.split(","));
        let idiomaArrayPlain = getIdiomas.flat();
        idiomaArrayPlain = [...new Set(idiomaArrayPlain)].map((idioma) => {
          return {
            value: idioma,
            text: this.$t(`messages.${idioma}`),
          };
        });
        this.optionsIdiomas = idiomaArrayPlain;
      }
    },
    async selectedSesionista(newValue) {
      this.fechaSeleccionada = null;
      this.horaSeleccionada = null;
      this.diasDisponibles = [];
      this.agendamientos = [];
      if (newValue) {
        this.getAgendamientos();
        const disponibilidadSesionista = this.disponibilidadSesionistas.filter(
          (disponibilidad) =>
            disponibilidad.id_sesionista === newValue &&
            disponibilidad.codigo_sesion === this.codigoSession
        );
        let diasDeLaSemenaDisponibles = disponibilidadSesionista.map(
          (disponibilidad) => disponibilidad.dia_semana
        );
        //delete repeat days
        diasDeLaSemenaDisponibles = [...new Set(diasDeLaSemenaDisponibles)];
        let today = this.$moment()
        today = today.add(2, 'days')
        for (let i = 0; i <= 30; i++) {
          const dayOfWeek = today.day();
          if (diasDeLaSemenaDisponibles.includes(dayOfWeek)) {
            this.diasDisponibles.push(today.format('YYYY-MM-DD'));
          }
          today = today.add(1, 'days')
        }
      }
    },
    async fechaSeleccionada(newValue) {
      this.horasDisponibles = [];
      if (!newValue) return;
      const getTipoSesionSelected = this.tiposSession.find(
        (tipoSesion) => tipoSesion.id === this.selectedTipoSesion
      );
      if (newValue && getTipoSesionSelected) {
        const dayOfWeekSeletected = new Date(
          newValue + "T00:00:00.000"
        ).getDay();
        let rangoDeHorasDisponibles = this.disponibilidadSesionistas
          .filter(
            (disponibilidad) =>
              disponibilidad.id_sesionista === this.selectedSesionista &&
              disponibilidad.dia_semana === dayOfWeekSeletected &&
              disponibilidad.codigo_sesion === this.codigoSession
          )
          .sort((a, b) => (a.desde > b.desde ? 1 : -1));

        rangoDeHorasDisponibles.forEach((rango) => {
          let initialHour = rango.desde;
          do {
            this.horasDisponibles.push({
              value: initialHour,
              text: this.secondsToHM(initialHour),
            });
            initialHour += 10 * 60;
          } while (
            initialHour + getTipoSesionSelected.duracion * 60 <
            rango.hasta
          );
        });
      }

      //Rangos ocupados de sesionista
      let rangosOcupadosLocales = this.agendamientos.map((item) => {
        const dateString = `${item.anio}-${item.mes < 10 ? "0" + item.mes : itesm.mes
          }-${item.dia < 10 ? "0" + item.dia : item.dia} ${this.secondsToHM(
            item.hora
          )}`;
        const jun = this.$moment.tz(dateString, item.zona_horaria);
        const local = jun.clone().tz(this.zona_horaria);
        return {
          dia: local.date(),
          inicio: local.hour() * 3600 + local.minute() * 60,
          fin: local.hour() * 3600 + local.minute() * 60 + item.duracion * 60,
        };
      });

      //hora ocupadas de usuario
      const rangosOcupadosLocalesDelCliente = this.agendamientosDelUsuario.map(
        (item) => {
          const dateString = `${item.anio}-${item.mes < 10 ? "0" + item.mes : itesm.mes
            }-${item.dia < 10 ? "0" + item.dia : item.dia} ${this.secondsToHM(
              item.hora
            )}`;
          const jun = this.$moment.tz(dateString, item.zona_horaria);
          const local = jun.clone().tz(this.zona_horaria);
          return {
            dia: local.date(),
            inicio: local.hour() * 3600 + local.minute() * 60,
            fin: local.hour() * 3600 + local.minute() * 60 + item.duracion * 60,
          };
        }
      );

      //union de ambos rangos
      rangosOcupadosLocales = rangosOcupadosLocales.concat(
        rangosOcupadosLocalesDelCliente
      );

      const date = this.$moment(newValue, "YYYY-MM-DD");
      const day = date.date();

      this.horasDisponibles = this.horasDisponibles.filter((rangoSelect) => {
        let isAvailable = true;
        rangosOcupadosLocales.forEach((rangoOcupado) => {
          if (rangoOcupado.dia === day) {
            if (
              (rangoSelect.value >= rangoOcupado.inicio &&
                rangoSelect.value < rangoOcupado.fin) ||
              (rangoSelect.value + getTipoSesionSelected.duracion * 60 >=
                rangoOcupado.inicio &&
                rangoSelect.value + getTipoSesionSelected.duracion * 60 <
                rangoOcupado.fin)
            ) {
              isAvailable = false;
            }
          }
        });
        return isAvailable;
      });
    },
    idiomaSeleccionado(newValue) {
      this.selectedSesionista = null;
      this.fechaSeleccionada = null;
      this.horaSeleccionada = null;
      this.optionsSesionistas = [];
      if (newValue) {
        if (this.sesionistas.length === 0) return [];
        const sesionistasEnEseSesion = this.sesionistasTipoSesion.filter(
          (sesionistasTipoSesion) =>
            sesionistasTipoSesion.id_tipo_sesion === this.selectedTipoSesion
        );
        const getIdSesionistasNoRepeat = [
          ...new Set(
            sesionistasEnEseSesion.map((sesionista) => sesionista.id_sesionista)
          ),
        ];

        const sesionistasFiltered = this.sesionistas
          .filter(
            (sesionista) =>
              getIdSesionistasNoRepeat.includes(sesionista.id) &&
              sesionista.idiomas.includes(newValue)
          )
          .map((sesionista) => {
            //get huso horario
            const husoHorario = this.husoHorarios.find(
              (husoHorario) => husoHorario.id === sesionista.id_huso_horario
            );

            return {
              value: sesionista.id,
              text: `${sesionista.nombre} ${sesionista.apellido} (${husoHorario.ciudad})`,
            };
          });
        this.optionsSesionistas = sesionistasFiltered;
      }
    },
    tiposSession(newValue) {
      this.agendamientosDelUsuario = [];
      if (newValue.length > 0 && this.getToken) {
        this.getAgendamientosUsuario();
      }
    },
  },
  computed: {
    ...mapGetters(["getToken"]),
    getTitle() {
      switch (this.activeStep) {
        case 0:
          return this.$t("messages.typeOfSession").toUpperCase();
        case 1:
          return this.$t("messages.language").toUpperCase();
        case 2:
          return this.$t("messages.channelerWhoWillAccompanyYou").toUpperCase();
        case 3:
          return this.$t("messages.dateAndTImeOfAppointment").toUpperCase();
        case 4:
          return this.$t("messages.summary").toUpperCase();
        default:
          return "";
      }
    },
    optionsTipoSesion() {
      if (this.tiposSession.length === 0) return [];
      return this.tiposSession.map((tipoSesion) => {
        return {
          value: tipoSesion.id,
          text: `${this.$t("nav." + tipoSesion.codigo)} - ${this.$t(
            "messages.maximumTime"
          )}: ${tipoSesion.duracion} min`,
        };
      });
    },
    getHusoHorario() {
      if (!this.selectedSesionista || this.husoHorarios.length === 0)
        return null;
      const getHusoHorarioSesionista = this.sesionistas.find(
        (sesionista) => sesionista.id === this.selectedSesionista
      );
      return this.husoHorarios.find(
        (husoHorario) =>
          husoHorario.id === getHusoHorarioSesionista.id_huso_horario
      );
    },
    getSesionSelected() {
      if (!this.selectedTipoSesion || this.tiposSession.length === 0)
        return null;
      return this.tiposSession.find(
        (tipoSesion) => tipoSesion.id === this.selectedTipoSesion
      );
    },
    getDescripcionTraslated() {
      if (!this.getSesionSelected) return "";
      return this.$t(`messages.${this.getSesionSelected.codigo}Mision`);
    },
    getSesionista() {
      if (!this.selectedSesionista || this.sesionistas.length === 0)
        return null;
      return this.sesionistas.find(
        (sesionista) => sesionista.id === this.selectedSesionista
      );
    },
    getSesionistaTipoDeSesion() {
      if (!this.selectedSesionista || !this.selectedTipoSesion) return null;
      return this.sesionistasTipoSesion.find(
        (sesionistaTipoSesion) =>
          sesionistaTipoSesion.id_sesionista === this.selectedSesionista &&
          sesionistaTipoSesion.id_tipo_sesion === this.selectedTipoSesion
      );
    },
    horaSesionista() {
      if (!this.selectedSesionista) return "";
      if (!this.fechaSeleccionada) return "";
      if (!this.horaSeleccionada) return "";
      const getSesionista = this.sesionistas.find(
        (sesionista) => sesionista.id === this.selectedSesionista
      );
      const getHusoHorario = this.husoHorarios.find(
        (husoHorario) => husoHorario.id === getSesionista.id_huso_horario
      );
      const date = new Date(
        `${this.fechaSeleccionada}T${this.secondsToHM(
          this.horaSeleccionada
        )}:00.000`
      );
      const a = this.$moment.tz(date, getHusoHorario.timezone);
      return a.format("HH:mm");
    },
    fechaHoraSesionista() {
      if (!this.selectedSesionista) return "";
      if (!this.fechaSeleccionada) return "";
      if (!this.horaSeleccionada) return "";
      const getSesionista = this.sesionistas.find(
        (sesionista) => sesionista.id === this.selectedSesionista
      );
      const getHusoHorario = this.husoHorarios.find(
        (husoHorario) => husoHorario.id === getSesionista.id_huso_horario
      );
      const date = new Date(
        `${this.fechaSeleccionada}T${this.secondsToHM(
          this.horaSeleccionada
        )}:00.000`
      );
      const a = this.$moment.tz(date, getHusoHorario.timezone);
      return a.format("dddd, DD-MM-YYYY HH:mm");
    },
    codigoSession() {
      if (!this.selectedTipoSesion) return "";
      const getSesion = this.tiposSession.find(
        (tipoSesion) => tipoSesion.id === this.selectedTipoSesion
      );
      return getSesion.codigo;
    },
    localizacionOpciones() {
      return [
        { value: 'inperson', text: this.$t("messages.inperson") },
        { value: 'online', text: this.$t("messages.online") }
      ]
    },
    dateFormated() {
      if (!this.fechaSeleccionada) return this.$t("messages.noDateSelected");
      const date = new Date(this.fechaSeleccionada);
      const a = this.$moment(date);
      return this.firstLetterToUpperCase(a.format(`dddd, DD MMMM [${this.$t("messages.of")}] YYYY`));
    },
    diaSemanaSesionista() {
      if (!this.selectedSesionista) return null;
      if (!this.fechaSeleccionada) return null;
      if (!this.horaSeleccionada) return null;
      const getSesionista = this.sesionistas.find(
        (sesionista) => sesionista.id === this.selectedSesionista
      );
      const getHusoHorario = this.husoHorarios.find(
        (husoHorario) => husoHorario.id === getSesionista.id_huso_horario
      );
      const date = new Date(
        `${this.fechaSeleccionada}T${this.secondsToHM(
          this.horaSeleccionada
        )}:00.000`
      );
      const a = this.$moment.tz(date, getHusoHorario.timezone);
      return a.day();
    }
  },
};
</script>

<style scoped lang="scss">
.container-cental {
  width: 100%;
  min-height: 700px;
}

.container-imagen {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
}

.fila-cabecera {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e8e8e8;
}

.fila {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid rgb(219, 219, 219);
}

.celda {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
  width: 14.2%;
  padding: 15px 0px;
}

.label-columna {
  display: none;
}

.label-total {
  font-size: 15px;
  font-weight: 600;
}

.total-value {
  font-size: 30px;
  font-weight: 900;
}

.titulo-separador {
  text-align: center;
  padding: 5px;
  background-color: #689f99;
  color: white;
  font-size: 1.2rem;
  letter-spacing: 4px;
  margin-top: 18px;
  font-weight: 600;
}

.item-categoria-add {
  list-style: none;
  padding: 5px 15px;
  background-color: #689f99;
  margin-right: 5px;
  border-radius: 19px;
  color: white;
  border: 1px solid gray;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  cursor: pointer;
  transition: 0.5s all;
}

.item-categoria-add:hover {
  background-color: #5b8883;
}

.item-categoria-add-selected {
  background-color: #436460;
}

.container-footer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 52px;
}

.container-total {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  justify-content: flex-start;
}

.container-total p {
  font-size: 1.4rem;
}

.container-botones {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  justify-content: flex-end;
}

.full {
  width: 100%;
}

.contenedor-seccion {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20%;
}

.container-titulo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-title {
  color: white;
  letter-spacing: 3px;
}

.container-calendar {
  display: flex;
  flex-direction: column;
  padding: 30px 30px;
  align-items: center;
  min-height: 400px;
  justify-content: center;
  align-items: center;
}

.container-hour {
  display: flex;
  flex-direction: column;
  padding: 30px 30px;
  align-items: center;
  min-height: 400px;
  justify-content: center;
}

.b-calendar-nav>button {
  color: gray !important;
}

.mesage-guide {
  display: block;
  padding: 0px 5px;
}

.container-descripcion-sesion {
  min-height: 100px;
  margin-top: 15px;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid rgb(175, 175, 175);
}

.container-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  margin-top: 20px;

  strong {
    text-align: center;
  }
}



@media (max-width: 600px) {
  .fila-cabecera {
    display: none;
  }

  .fila {
    padding-bottom: 15px;
  }

  .celda {
    width: 100%;
    justify-content: flex-start;
    padding: 5px 0px;
  }

  .label-columna {
    display: block;
    width: 120px;
    font-size: 13px;
    font-weight: bold;
  }

  .celda-imagen {
    justify-content: center;
  }

  .container-imagen {
    width: 75px;
    height: 75px;
    overflow: hidden;
    border-radius: 50%;
  }

  .celda-total-vacia {
    display: none;
  }

  .celda-total-data {
    width: 50%;
    padding-left: 10px;
  }

  .total-value {
    margin: 0px auto;
    font-size: 20px;
    font-weight: 900;
  }

  .label-total {
    font-size: 12px;
    font-weight: 600;
  }

  .container-total,
  .container-botones {
    width: 100%;
    justify-content: space-around;
  }

  .container-calendar {
    padding: 10px 0px;
  }

  .container-hour {
    text-align: center;
    padding: 0px 10%;
    min-height: 0px;
  }

  .contenedor-seccion {
    padding: 0px 10%;
  }

  .mesage-guide {
    display: none;
  }
}
</style>
