import axios from "axios";

export default class CalendarioService {
  createCalendar(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "calendario",data)
  }

  elMesTienePedidos(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "calendario/have-requests",data)
  }

  diasDelCalendarioYRangos(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "calendario/mes",data)
  }

  diasYHorasDisponibles(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "calendario/horas-disponibles",data)
  }
  crearAgendamiento(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "agendamiento/create",data)
  }

  getAgendamientosBySelect(id){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "agendamientos/canalizadora/"+id)
  }

  getAgendamientosByIdUsuario(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "agendamientos/cliente")
  }

  getAgendamientosByIdUsuarioAll(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "agendamientos/cliente/full")
  }

  getAgendamientosByIdForCliente(id){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "agendamientos/cliente/"+id)
  }

  cancelarAgendamientoById(data){
    return axios.put(process.env.VUE_APP_API_PUBLIC + "agendamiento/cliente/cancelacion",data)
  }

}
